import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserResponse, IUser, IUserRegister, IProvinceResponse, IProvincia } from 'src/app/interfaces/user.interface';
import { IFileDownload } from 'src/app/interfaces/product.interface'; import { IErrorData, IError } from 'src/app/interfaces/error.interface'; import { API } from '../API.enum';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CartService } from '../cart/cart.service';
import { FavoritesService } from '../favorites/favorites.service';
import { StorageService } from '../../services/storage/storage.service';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public isChecked: boolean = false;
  public data: IUser | null = null;
  public isLoggedIn: boolean = false;
  public token: string = '';
  public provincias: IProvincia[] = [];

  //
  public titleLogin: string = '';
  public routeBeforeLogin: string = '/';

  /**
   * UserService
   */
  constructor(
    private router: Router,
    private http: HttpClient,
    private cartService: CartService,
    private favoriteService: FavoritesService,
    private storageService: StorageService
  ) { }

  /**
   * login
   * @param user
   * @param password 
   */
  public login(user: string, password: string): Observable<IUserResponse> {
    return this.http.post<IUserResponse>(
      API.BASE + API.LOGIN,
      {
        User: user,
        Password: password
      }
    );
  }

  /**
   * checkUser
   */
  public checkUser(): void {
    this.storageService.getItem('session', null)
      .then((response) => {
        //valido que el token no este vencido
        var dateSub = Number(response.data.Expires.toString().substring(6, 19));
        var dateNow = Date.now();

        if (dateNow > dateSub) {

          this.favoriteService.clearList();
          this.cartService.clearList();
          this.setData(null, false);
          this.router.navigate(['/']);
        } else {
          const data: IUserResponse | null = response.data;
          if (data !== null && data.User && data.Token) {
            this.data = data.User;
            this.token = data.Token;
            this.isLoggedIn = true;
            this.cartService.loadCart();
            this.favoriteService.loadFavorites();
          }
          this.isChecked = true;
        }
      })
      .catch((error) => {
        this.isChecked = true;
      });
  }


  public validate(key: string): Observable<IErrorData> {
    return this.http.post<IErrorData>(
      API.BASE + API.VALIDATEKEY,
      {
        ActivationKey: key,
      }
    );
  }


  /*
  *
  Activate 
  */
  public activate(key: string): Observable<IErrorData> {
    return this.http.post<IErrorData>(
      API.BASE + API.ACTIVATE,
      {
        ActivationKey: key,
      }
    );
  }

  /**
   * ResetPassword
   *
   */
  public resetPassword(email: string): Observable<IUserRegister> {
    return this.http.post<IUserRegister>(
      API.BASE + API.PASSWORD_RESET,
      {
        Email: email,
      }
    );
  }

  /**
   * changePassword 
   * 
   * */
  public changePassword(key: string, password: string): Observable<IErrorData> {
    return this.http.post<IErrorData>(
      API.BASE + API.PASSWORD_CHANGE,
      {
        ActivationKey: key,
        Password: password
      }
    );
  }

  public editProfile(
    sucursalID: number, 
    vendedorID: number, 
    password: string,
    nroDocumento: string,
    calle: string,
    codPostal: string,
    localidad: string,
    piso: string,
    provinciaId: number,
    puerta: string,
    telefono: string
    ): Observable<IErrorData> {
    return this.http.post<IErrorData>(API.BASE + API.PROFILE_EDIT, {
      SucursalID: sucursalID,
      VendedorID: vendedorID,
      Password: password,
      NroDocumento: nroDocumento,
      Calle: calle,
      CodPostal: codPostal,
      Localidad: localidad,
      Piso: piso,
      ProvinciaId: provinciaId,
      Puerta: puerta,
      Telefono: telefono
    });
  }

  /**
   * 
   * @param cuit 
   * @param password 
   * @param email 
   * @param businessName 
   * @param phone 
   * @param street 
   * @param door 
   * @param floor 
   * @param dept 
   * @param location 
   * @param provinceID 
   * @param postalCode 
   * @param isCliente 
   */
  public register(
    cuit: string,
    password: string,
    email: string,
    businessName: string,
    phone: string,
    street: string,
    door: number,
    floor: string,
    dept: string,
    location: string,
    provinceID: number,
    postalCode: string,
    isCliente: boolean,
    sucursalID: number,
    vendedorID: number,
    esConsumidorFinal: boolean
  )

    : Observable<IUserRegister> {
    return this.http.post<IUserRegister>(
      API.BASE + API.REGISTER,
      {
        // Name: name,
        CUIT: cuit,
        RazonSocial: businessName,
        Calle: street,
        Puerta: door,
        Piso: floor,
        Depto: dept,
        Localidad: location,
        ProvinciaID: provinceID,
        CodigoPostal: postalCode,
        Telefono: phone,
        Email: email,
        Password: password,
        EsCliente: isCliente,
        SucursalID: sucursalID,
        VendedorID: vendedorID,
        EsConsumidorFinal: esConsumidorFinal
      }
    );
  }

  public getProvinces(): Observable<IProvincia[]> {
    return new Observable((observer) => {
      this.http.post<IProvinceResponse>(API.BASE + API.PROVINCE_LISTA, {}).subscribe((asyncData: IProvinceResponse) => {
        observer.next(asyncData.Provincias);
        observer.complete();
      });
    });
  }


  public checkUserBeforeAction(title?: string): boolean {
    if (this.isLoggedIn === true && this.token !== '') {
      return true;
    } else {
      this.titleLogin = title;
      this.routeBeforeLogin = this.router.url; // Save route before login
      this.router.navigate(['user/login']);
      return false;
    }
  }

  public logout(): void {
    this.favoriteService.clearList();
    this.cartService.clearList();
    this.setData(null, false);
    this.router.navigate(['/']);
    setTimeout(() => { //window.location.reload(); 
    }, 200);
  }

  public setData(data: IUserResponse | null, isLogged: boolean): void {
    //console.log(data);
    this.data = (data) ? data.User : null;
    this.token = (data) ? data.Token : '';
    this.isLoggedIn = (data) ? isLogged : false;
    this.storageService.setItem('session', data);
    if (data != null)
    {
      this.cartService.loadCart();
      this.favoriteService.loadFavorites();
    }
  }


  public getName(): string {
    return (this.data) ? this.data.FullName : '';
  }

  public getCustomerId(): number {
    return (this.data) ? this.data.CustomerId : 0;
  }

  public getConsumidorFinal(): boolean {
    return (this.data) ? this.data.EsConsumidorFinal : false;
  }

  public getUserId(): number {
    return (this.data) ? this.data.UserId : 0;
  }


  public getRouteAfterLogin(): string {
    const route: string = this.routeBeforeLogin;
    this.routeBeforeLogin = '/';
    return route;
  }


  public contact(
    name: string,
    email: string,
    provinceID: number,
    province: string,
    city: string,
    phone: string,
    message: string
  )
    : Observable<IErrorData> {
    return this.http.post<IErrorData>(
      API.BASE + API.CONTACT,
      {
        Name: name,
        Email: email,
        ProvinceID: provinceID,
        Province: province,
        City: city,
        Phone: phone,
        Message: message
      }
    )
  }
  /**
   * file download 
   * */

  //TODO falta parametro?
  public fileDownload(): Observable<IFileDownload> {
    return this.http.post<IFileDownload>(
      API.BASE + API.FILE_DOWNLOAD, {

    }
    );
  }

  public loginStatus(): Observable<any> {
    return this.http.post(API.BASE + API.LOGIN_STATUS, {});
  }
  /*public  UserHistoryDate
  (
      FechaDesde: Date,
      FechaHasta:Date
  )*/
  public getHistorial(desde: Date, hasta: Date): Observable<any> {
   


    return this.http.post(API.BASE + API.HISTORY_LIST, {
      FechaDesde: desde,
      FechaHasta: hasta
    });
  }

  public historialFileDownload(Nombre: String): Observable<any> {
    return this.http.post(API.BASE + API.HISTORY_DOWNLOAD, { Nombre  });
  }

}
