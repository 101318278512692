import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './e-commerce/modules/user/modules/login/login.component';


const routes: Routes = [
  { path: '', loadChildren: './e-commerce/e-commerce.module#ECommerceModule' },
  { path: 'errors', loadChildren: './errors/errors.module#ErrorsModule'  },
  { path: '**', redirectTo: '' }  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
