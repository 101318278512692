import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { API } from '../API.enum';
import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { IProduct, IProductsResponse } from 'src/app/interfaces/product.interface';
import { ICartResponse, ICart, IProject } from 'src/app/interfaces/cart.interface';
import { IErrorData } from 'src/app/interfaces/error.interface';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cart: any = [];
  public enabled: boolean;
  private list = new BehaviorSubject<any[]>([]);
  list$ = this.list.asObservable();

  private Total: number;
  private TotalBehavior = new BehaviorSubject<number>(0);
  Total$ = this.TotalBehavior.asObservable();

  private CantTotal: number;
  private CantBehavior = new BehaviorSubject<number>(0);
  Cant$ = this.CantBehavior.asObservable();

  private paso: number;
  private pasoBehavior = new BehaviorSubject<number>(0);
  paso$ = this.pasoBehavior.asObservable();

  public RetiroId: number;
  public TipoDeRetiroId: number;
  public Observaciones: string = '';
  public OpcionesPagoId: number;
  public CodCompId: number;
  public Sucursal: number;
  public NroComp: number;
  public SucursalPed: number;
  public CodCompIdPed: number;
  public NroCompPed: number;
  public PreferenceId: string;
  public PaymentId: number;
  public TotalMP: number;

  public CuponId: number;
  public Porcentaje: number;

  public mensajeError: string;

  public Proyecto: IProject = {
    name: "",
    fechaLimite: "",
    fechaDecision: "",
    fechaProvision: "",
    nameFinal: "",
    nameContacto: "",
    emailCliFinal: "",
    presupuestoId: 0,
    contratacionId: 0,
    tipoProyectoId: 0,
    categoriaId: 0,
    zonaId: 0,
    nameFirma: "",
    nameResp: "",
    emailResp: "",
    phoneResp: "",
    phoneCliFinal: "",
    despProyecto: "",
    equipamiento: "",
    competidores: "",
    observaciones: "",
    nameDist: "",
    nameDistResp: "",
    emailDistResp: "",
    phoneDistResp: "",
    ClienteId: 0,
    products: []
  };

  public Combos: any;

  constructor(private storageService: StorageService, private http: HttpClient) {
    this.init();
  }

  public loadCart() {
    this.cart = [];
    this.http.post<ICartResponse>(API.BASE + API.CART_LIST, {}).subscribe((data: any) => {
      data.Products.forEach((product: ICart) => {
        this.cart.push({ ProductId: product.ProductId, counter: product.Quantity, precio: product.Price });
      });
      this.updateStorage();
    }, (error) => {
      console.log(error);
    });
  }

  public async init() {
    this.cart = [];
    this.list.next(this.cart);
    this.updateStorage();
    // const List: any = await this.storageService.getItem('cart_list', []);
    // this.cart = List.data;

    this.Total = 0;
    this.TotalBehavior.next(this.Total);

    this.CantTotal = 0;
    this.CantBehavior.next(this.CantTotal);

    this.paso = 0;
    this.pasoBehavior.next(this.paso);


  }

  public updateStorage(): void {
    this.storageService.setItem('cart_list', this.cart);
  }

  public  cartStatus(): Observable<any> {
    return this.http.post(API.BASE + API.CART_STATUS, {});
  }

  public getPayment(importe: number): Observable<any> {
    return this.http.post(API.BASE + API.CART_GETPAYMENT, { Total: importe });
  }

  public checkPayment(preferenceId: string): Observable<any> {
    return this.http.post(API.BASE + API.CART_CHECKPAYMENT, { PreferenceId: preferenceId });
  }

  public getTaxes(): Observable<any> {
    return this.http.post(API.BASE + API.CART_TAXES, { TipoDeRetiroId: this.TipoDeRetiroId, RetiroId: this.RetiroId, CuponId: this.CuponId });
  }

  public saveInvoice(PaymentId: number, Total: number, EsPedido: boolean): Observable<any> {
    return this.http.post(API.BASE + API.CART_INVOICE, {
      PaymentId: PaymentId,
      Total: Total,
      TipoDeRetiroId: this.TipoDeRetiroId,
      RetiroId: this.RetiroId,
      EsPedido: EsPedido,
      CodCompId: this.CodCompId,
      Sucursal: this.Sucursal,
      NroComp: this.NroComp,
      CuponId: this.CuponId
    });
  }


  /************************************************/
  /* List                                         */
  /************************************************/

  public addToList(ProductId: number, counter: number, precio: number): void {
    const index: number = this.cart.findIndex((item) => item.ProductId == ProductId);
    if (index === -1) {
      this.cart.push({ ProductId: ProductId, counter: counter, precio: precio });
    } else {
      this.cart[index].counter = counter;
    }

    this.updateStorage();

    this.list.next(this.cart);
    this.CalculateTotal();
    this.CalculateCantTotal();
    this.http.post<any>(API.BASE + API.CART_UPDATE, { ProductId: ProductId, Quantity: counter }).subscribe();

  }

  public CalculateTotal() {
    let sum: number = 0;
    //valido si el cupon sigue vigente y si no lo esta lo elimino, con un snackbar avisando!
    this.validateCupon('', this.CuponId).subscribe((data: any) => {
      if (data.HayError) {
        this.CuponId = null;
        this.Porcentaje = null;
        this.storageService.setItem("CuponId", null);
        this.storageService.setItem("Porcentaje", null);
        this.storageService.setItem("Codigo", null);        
      }
    });

    // this.cart.forEach(x => sum += (x.precio * x.counter));
    this.cart.forEach(x => sum += this.CuponId != null ? ((x.precio - (x.precio * this.Porcentaje / 100)) * x.counter) : (x.precio * x.counter));
    this.Total = sum;
    this.TotalBehavior.next(this.Total);
  }

  public CalculateCantTotal() {
    let sum: number = 0
    this.cart.forEach(x => sum += (x.counter));
    this.CantTotal = sum;
    this.CantBehavior.next(this.CantTotal);
  }

  public SetearPaso(pasoNumero: number): void {
    this.paso = pasoNumero;
    this.pasoBehavior.next(this.paso);
  }

  public getTotales(): number {
    let sum: number = 0
    //valido si el cupon sigue vigente y si no lo esta lo elimino, con un snackbar avisando!
    this.validateCupon('', this.CuponId).subscribe((data: any) => {
      if (data.HayError) {
        this.CuponId = null;
        this.Porcentaje = null;
        this.storageService.setItem("CuponId", null);
        this.storageService.setItem("Porcentaje", null);
        this.storageService.setItem("Codigo", null);
      }
    });


    if (this.cart.length > 0) {
      this.cart.forEach(x => sum += this.CuponId != null ? ((x.precio - (x.precio * this.Porcentaje / 100)) * x.counter) : (x.precio * x.counter));
      this.Total = sum;
      this.TotalBehavior.next(this.Total);
    }
    return sum;
  }

  public getCantTotales(): number {
    let sum: number = 0
    if (this.cart.length > 0) {
      this.cart.forEach(x => sum += (x.counter));
      this.CantTotal = sum;
      this.CantBehavior.next(this.CantTotal);
    }
    return sum;
  }

  public removeFromList(ProductId: number): void {
    const index: number = this.cart.findIndex((item) => item.ProductId == ProductId);
    if (index !== -1) {
      this.cart.splice(index, 1);
    }
    this.updateStorage();

    this.list.next(this.cart);

    this.CalculateTotal();
    this.CalculateCantTotal();
    this.http.post<any>(API.BASE + API.CART_UPDATE, { ProductId: ProductId, Quantity: 0 }).subscribe();

  }

  public getCartItemCounter(ProductId: number): number {
    const index: number = this.cart.findIndex((item) => item.ProductId == ProductId);
    if (index === -1) {
      return 0;
    } else {
      return Math.floor(Math.max(0, this.cart[index].counter));
    }
  }

  public isAdded(ProductId: number): boolean {
    const index: number = this.cart.findIndex((item) => item.ProductId == ProductId);
    return (index !== -1);
  }

  public clearList(): void {
    // this.Total = 0;
    // this.CantTotal = 0;
    // this.list.next(this.cart);
    // this.TotalBehavior.next(this.Total);
    // this.CantBehavior.next(this.CantTotal);
    this.RetiroId = 0;
    this.cart = [];
    this.updateStorage();
    this.Observaciones = '';
    this.storageService.setItem('RetiroPedido', '');
    this.storageService.setItem('RetiroNombre', '');
    this.storageService.setItem('RetiroDireccion', '');
    this.storageService.setItem('Observaciones', '');
    this.storageService.setItem('TipoDeRetiroId', '');
    this.storageService.setItem('PreferenceId', '');
    this.storageService.setItem('OpcionesPagoId', null);

    this.storageService.setItem('CuponId', null);
    this.storageService.setItem('Porcentaje', null);
    this.storageService.setItem('Codigo', null);

    //TODO limpiar proyecto
    this.Proyecto = undefined;
  }

  public getList(): any {
    return this.storageService.getItem('cart_list', []);
  }

  public getTotal(): void {
    this.TotalBehavior.next(this.Total);
  }

  public getCantTotal(): void {
    this.CantBehavior.next(this.CantTotal);
  }

  public setTotal(ProductId: number, importe: number): void {
    this.Total += importe;
    this.TotalBehavior.next(this.Total);
  }

  public getCartFromDb(): Observable<ICartResponse> {
    return new Observable((observer) => {
      this.http.post<ICartResponse>(API.BASE + API.CART_LIST, {}).subscribe((asyncData: ICartResponse) => {
        observer.next(asyncData);
        observer.complete();
      });
    });
  }

  public ConfirmCart(mercadopago: boolean): Observable<IErrorData> {
    var request = this.resetRequest();
    request.MercadoPago = mercadopago;
    return this.http.post<IErrorData>(API.BASE + API.CART_CONFIRM, request)
  }

  public resetRequest(): any {
    return {
      RetiroID: this.RetiroId,
      TipodeRetiroID: this.TipoDeRetiroId,
      Observations: this.Observaciones,
      EsProyecto: this.Proyecto !== undefined ? true : false,
      Proyecto: this.Proyecto,
      PreferenceID: this.PreferenceId,
      CodCompId: this.CodCompId,
      Sucursal: this.Sucursal,
      NroComp: this.NroComp,
	    CodCompIdPed: this.CodCompIdPed,
      SucursalPed: this.SucursalPed,
      NroCompPed: this.NroCompPed,
      CuponId: this.CuponId    }
  }

  // public getCartFromDb(): Observable<ICartResponse> {
  //   return new Observable((observer) => {
  //     this.http.post<ICartResponse>(API.BASE + API.CART_LIST, {}).subscribe((asyncData: ICartResponse) => {
  //       observer.next(asyncData);
  //       observer.complete();
  //     });
  //   });
  // }

  public getCombos(): any {
    return this.http.post<any>(API.BASE + API.CART_COMBOS, {});
    // .subscribe((asyncData) => {

    //   this.Combos = asyncData;

    // });
  }

  public getProject() {
    return {
      "Name": this.Proyecto.name,
      "FechaLimite": this.Proyecto.fechaLimite,
      "FechaDecision": this.Proyecto.fechaDecision,
      "FechaProvision": this.Proyecto.fechaProvision,
      "NameFinal": this.Proyecto.nameFinal,
      "NameContacto": this.Proyecto.nameContacto,
      "EmailCliFinal": this.Proyecto.emailCliFinal,
      "PresupuestoId": this.Proyecto.presupuestoId,
      "TipoProyectoId": this.Proyecto.tipoProyectoId,
      "CategoriaId": this.Proyecto.categoriaId,
      "ContratacionId": this.Proyecto.contratacionId,
      "ZonaId": this.Proyecto.zonaId,
      "NameFirma": this.Proyecto.nameFirma,
      "NameResp": this.Proyecto.nameResp,
      "EmailResp": this.Proyecto.emailResp,
      "PhoneResp": this.Proyecto.phoneResp,
      "PhoneCliFinal": this.Proyecto.phoneCliFinal,
      "DespProyecto": this.Proyecto.despProyecto,
      "Equipamiento": this.Proyecto.equipamiento,
      "Competidores": this.Proyecto.competidores,
      "Observaciones": this.Proyecto.observaciones,
      "NameDist": this.Proyecto.nameDist,
      "NameDistResp": this.Proyecto.nameDistResp,
      "EmailDistResp": this.Proyecto.emailDistResp,
      "PhoneDistResp": this.Proyecto.phoneDistResp,
      "Products": this.Proyecto.products,
      "ClienteId": this.Proyecto.ClienteId
    }
  }

  public setearProyecto(): Observable<IErrorData> {
    return this.http.post<IErrorData>(API.BASE + API.SAVE_PROJECT, this.getProject());
  }

  public validateCupon(codigo: string, cuponId: number): Observable<any> {
    return this.http.post<any>(API.BASE + API.CART_VALIDATECOUPON, {
      "CouponCode": codigo,
      "CuponId": cuponId
    });
  }

}
