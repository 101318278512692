export enum API {
  /* Debug */
  //  BASE = 'http://localhost:52498',

    /* Internal */
  //BASE = 'http://192.168.0.50/TestBigDipperApi',

  /* Internal Test*/
  //BASE = 'http://192.168.0.50/TestBigDipperAPI',

  /* Internal Demo*/
  // BASE = 'http://192.168.0.50/BigDipperAPIDemo',

  /* External */
  // BASE = 'http://200.89.141.10:17010/BigDipper',

  /* External Demo */
  //BASE = 'http://200.89.141.10:17010/BigDipperAPIDemo',

  /* Produccion */
  BASE = 'https://www2.bigdipper.com.ar',

  /*Preprod*/ 
  // BASE = 'https://www2.bigdipper.com.ar:8443',

 //FRONTAPI_BASE = 'http://192.168.0.50/BigDipperFrontAPI',

  /* External Demo FRONTAPI */
  // FRONTAPI_BASE = 'http://200.89.141.10:17010/BigDipperFrontAPI',

  /* External Demo FRONTAPI */
  FRONTAPI_BASE = 'https://www.bigdipper.com.ar:9005',
  

  HOME_HEADER = '/api/Landing/BannerList',
  
  HOME_BRANDS = '/api/Landing/BrandsList',
  HOME_PRODUCTS = './assets/data/home/products.json',
  // HOME_SOLUTIONS = './assets/data/home/solutions.json',
  HOME_SOLUTIONS = '/api/Landing/SolucionList',

  HOME_LINKS = './assets/data/home/links.json',

  ACCOUNT_FILEDOWNLOAD = '/api/AccountApi/FileDownload',
  LOGIN = '/api/AccountApi/Login',
  REGISTER = '/api/AccountApi/Register',
  ACTIVATE = '/api/AccountApi/Activate',
  VALIDATEKEY = '/api/AccountApi/ValidateKey',
  REGISTER_CONFIRM = '',
  PASSWORD_CHANGE = '/api/AccountApi/ChangePassword',
  PASSWORD_RESET = '/api/AccountApi/ResetPassword',
  PROFILE_VIEW = '',
  PROFILE_EDIT = '/api/AccountApi/Edit',
  GET_USER_DATA = '',
  PROVINCE_LISTA = '/api/AccountApi/Provincias',
  LOGIN_STATUS = '/api/AccountApi/Status',

  FILE_DOWNLOAD = '/api/Products/PricesList',

  PRODUCT_LIST = '/api/Products/List',
  PRODUCT_VIEW = '/api/Products/View',
  PRODUCT_CATEGORIES = '/api/Products/Categories',
  PRODUCT_BRANDS = '/api/Products/BrandsAndTags',
  PRODUCT_TAGS = '/api/Products/BrandsAndTags',
  PRODUCT_TOPSALES = '/api/Products/TopSales',

  CART_LIST = '/api/Cart/List',
  CART_CONFIRM = '/api/Cart/Confirm',
  CART_UPDATE = '/api/Cart/Update',
  CART_COMBOS = '/api/Cart/GetCombos',
  CART_STATUS = '/api/Cart/Status',
  CART_GETPAYMENT = '/api/Cart/GetPayment',
  CART_PAYMENTCALLBACK = '/api/Cart/PaymentCallback',
  CART_CHECKPAYMENT = '/api/Cart/CheckPayment',
  CART_TAXES = '/api/Cart/GetTaxes',
  CART_INVOICE = '/api/Cart/SaveInvoice',

  FAVORITE_LIST = '/api/Favorites/List',
  FAVORITE_UPDATE = '/api/Favorites/Update',

  ORDERS_LIST = '',
  CATALOGS_LIST = '/api/landing/CatalogoList',
  INSTRUCTIONS_LIST = '/api/instructivos/InstructivoList',
  //INSTRUCTIONS_LIST = '/api/landing/InstructivoList',
  WEBINAR_LIST = '/api/landing/WebinarList',
  BRANCHES_LIST = '/api/landing/SucursalList',
  STORIES_LIST = '/api/landing/CasosDeExitosList',
  ABOUTUS_LIST = '/api/Landing/ManualDeMarcaList',
  VENDORS_LIST = '/api/Landing/ListVendedoresXSucursal',
  DOLAR_QUOTATION = '/api/Landing/Cotizacion',
  CONTACT = '/api/Landing/Contact',
  SUBSCRIBE_MAILLIST = '/api/Landing/SubscribeMailList',
  SAVE_PROJECT = '/api/Projects/SaveProject',

  TRANS_LIST = '/api/Landing/TransportsList',
  ADDRESSES_LIST = '/api/Landing/AddressesList',

  ART_BUSCAR = '/api/Articulos/Buscar',  

  HISTORY_LIST = '/api/AccountApi/GetHistorial',

  OPCIONESPAGO_LIST = '/api/Landing/OpcionesPagoList',

  HISTORY_DOWNLOAD = '/api/AccountApi/HistorialFileDownload',

  CART_VALIDATECOUPON = '/api/Cart/ValidateCoupon'
}
